// extracted by mini-css-extract-plugin
export var carouselContainer = "G_rD d_w d_H d_bf d_Z";
export var carouselContainerCards = "G_vM G_rD d_w d_H d_bf d_Z";
export var carouselContainerSides = "G_vN d_w d_H d_Z";
export var prevCarouselItem = "G_vP d_w d_H d_0 d_k";
export var prevCarouselItemL = "G_vQ G_vP d_w d_H d_0 d_k";
export var moveInFromLeft = "G_vR";
export var prevCarouselItemR = "G_vS G_vP d_w d_H d_0 d_k";
export var moveInFromRight = "G_vT";
export var selectedCarouselItem = "G_vV d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "G_vW G_vV d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "G_vX G_vV d_w d_H d_Z d_bf";
export var nextCarouselItem = "G_vY d_w d_H d_0 d_k";
export var nextCarouselItemL = "G_vZ G_vY d_w d_H d_0 d_k";
export var nextCarouselItemR = "G_v0 G_vY d_w d_H d_0 d_k";
export var arrowContainer = "G_v1 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "G_v2 G_v1 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "G_v3 G_v2 G_v1 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "G_v4 G_v1 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "G_v5";
export var nextArrowContainerHidden = "G_v6 G_v4 G_v1 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "G_kG d_0";
export var prevArrow = "G_v7 G_kG d_0";
export var nextArrow = "G_v8 G_kG d_0";
export var carouselIndicatorContainer = "G_v9 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "G_wb d_w d_bz d_bF";
export var carouselText = "G_wc d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "G_wd G_wc d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "G_wf d_b7";
export var carouselIndicator = "G_wg G_wf d_b7";
export var carouselIndicatorSelected = "G_wh G_wf d_b7";
export var arrowsContainerTopRight = "G_wj d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "G_wk d_0 d_bl d_bC";
export var arrowsContainerSides = "G_wl d_0 d_bl d_bC";
export var smallArrowsBase = "G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "G_wn G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "G_wp G_wn G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "G_wq G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "G_wr G_wq G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "G_rr";
export var iconSmall = "G_ws";
export var multipleWrapper = "G_wt d_bC d_bF d_bf";
export var multipleImage = "G_wv d_bC";
export var sidesPrevContainer = "G_ww G_wn G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "G_wx G_wn G_wm d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_ss d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_st d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sv d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sw d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sx d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sy d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sz d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_sB C_sB";
export var heroExceptionNormal = "v_sC C_sC";
export var heroExceptionLarge = "v_sD C_sD";
export var Title1Small = "v_sF C_sF C_th C_tj";
export var Title1Normal = "v_sG C_sG C_th C_tk";
export var Title1Large = "v_sH C_sH C_th C_tl";
export var BodySmall = "v_sJ C_sJ C_th C_tB";
export var BodyNormal = "v_sK C_sK C_th C_tC";
export var BodyLarge = "v_sL C_sL C_th C_tD";
// extracted by mini-css-extract-plugin
export var alignLeft = "w_ry d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_rz d_bH";
export var textAlignLeft = "w_sM";
export var textAlignCenter = "w_sN";
export var textAlignRight = "w_sP";
export var hoursInnerWrapperAlt = "w_sQ d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "w_sR d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "w_sS d_dw";
export var titleMargin = "w_sT d_cw";
export var hoursInnerInnerWrapper = "w_sV d_cz";
// extracted by mini-css-extract-plugin
export var articleWrapper = "p_qS";
export var articleText = "p_qT d_dv d_cs";
export var header = "p_qV d_Z d_w";
export var headerImageWrapper = "p_qW d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "p_qX d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "p_qY d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "p_qZ d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "p_q0 d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "p_mv d_w";
export var dateTag = "p_q1 d_cf d_cr d_dq d_w";
export var icon1 = "p_q2 d_cP";
export var icon2 = "p_q3 d_cP d_cJ";
export var tagBtn = "p_q4 d_b1 d_cD d_dp d_dB";
export var headerText = "p_q5 d_w d_cf d_cs d_bW";
export var center = "p_q6 d_dw";
export var videoIframeStyle = "p_q7 d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "p_q8 d_cs d_Z";
export var articleImageWrapperIcon = "p_q9 d_cs d_Z";
export var articleRow = "p_rb d_bD";
export var quoteWrapper = "p_gQ d_bC d_bP d_cs";
export var quoteBar = "p_rc d_H";
export var quoteText = "p_rd";
export var authorBox = "p_rf d_w";
export var authorRow = "p_rg d_bD d_bW d_cy";
export var separator = "p_rh d_w";
export var line = "p_fk d_w d_fk d_cY d_cy";
export var authorImage = "p_ln d_b7 d_Z d_w d_ct";
export var authorText = "p_rj d_ch d_ct";
export var masonryImageWrapper = "p_rk";
export var bottomSeparator = "p_rl d_w d_cy";
export var linksWrapper = "p_rm d_ch d_dv";
export var comments = "p_rn d_bD d_cy";
export var sharing = "p_rp d_bz d_bD d_bJ";
export var shareText = "p_rq d_w d_dw";
export var icon = "p_rr";
export var customRow = "p_rs d_bD d_Z";
export var text = "p_rt";
export var SubtitleSmall = "p_rv";
export var SubtitleNormal = "p_rw";
export var SubtitleLarge = "p_rx";